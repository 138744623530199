import { PublicStore } from "../../../../store/PublicStore";
import Container from "../../../Atoms/layouts/container/Container";
import SectionHeader from "../../../Atoms/text_labels/SectionHeader";
import style from "./eachApp.module.scss";

export default function OurAppsSection() {

  const { globalData } = PublicStore();

  return (
    <div className="py-80">
      <Container>
        <SectionHeader>
          Our Products
        </SectionHeader>


        <div className={`mt-80 ${style.AppList}`}>
          {globalData?.nav_menu?.map(app => (
            <a key={app.id} href={app.url} rel="noreferrer" className="d-block">
              <ion-icon name={app.icon_name}></ion-icon>
              <h4>{app.short_name}</h4>
            </a>
          ))}
        </div>

      </Container>
    </div>
  )
}