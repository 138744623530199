import { create } from "zustand";
import Api from "../services/api/Api";
import { public_api } from "../services/api/ApiList";

const initialState = {
  loading: false,
  success: false,
  error: false,
  globalData: null,
  errorData: null,
};

export const PublicStore = create((set, get) => ({
  ...initialState,

  fetchData: async () => {
    set({ ...initialState, loading: true });
    try {
      const res = await Api.get(public_api.get_global_config);
      set({ ...initialState, success: true, globalData: res.data?.body });
    } catch (err) {
      console.error("Error in data fetch:", err);
      set({ ...initialState, error: true, errorData: err.message });
    }
  },
}));