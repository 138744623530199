
const public_api = {
  submit_internships: "/internship/submit",
  get_global_config: "/lushai_company/website-config",
  get_job_openings: "/career/get-job-openings",
  submit_job_application: "/career/submit-job-application",
 
}

const admin_api = {
  get_interns: "/internship/applicants",
  get_job_applicants: "/career/get-job-applicants",
}

Object.freeze(public_api);
Object.freeze(admin_api);

export {
  public_api,
  admin_api
}