import Container from "../../Components/Atoms/layouts/container/Container";

export default function TermsConditions() {
  return (
    <div className="other-page-container color-brand-color1 bg-color-brand-color4">
      <Container className="">
        <div className="py-80 simple-para">
          <h2>
            Terms and Conditions
          </h2>
          <p>
            These Terms and Conditions ("Terms") govern your use of www.diaspora.lushaitech.com website and mobile application (collectively referred to as the "Platform"). By accessing or using the Platform, you agree to be bound by these Terms. If you disagree with any part of the terms, you may not access the Platform.
          </p>

          <ol className="long-text-body-main-ol">
            <li>
              <h4>Registration and Fees</h4>
              <ol>
                <li>To access certain features of the Platform, you may be required to register and pay applicable fees.</li>
                <li>
                  By registering on the Platform, you agree to provide accurate, current, and complete information about yourself as prompted by the registration form.
                </li>
                <li>You agree to pay all fees and charges incurred on your account, including applicable taxes, in accordance with the fee schedule and payment terms provided to you.
                </li>
              </ol>
            </li>
            <li>
              <h4>User Conduct</h4>
              <ol>
                <li>
                  You agree to use the Platform for lawful purposes only and in compliance with all applicable laws and regulations.
                </li>
                <li>
                  You agree not to engage in any conduct that:
                  <ul>
                    <li>
                      Violates or infringes upon the rights of others;
                    </li>
                    <li>
                      Is harmful, threatening, abusive, harassing, defamatory, or otherwise objectionable;
                    </li>
                    <li>
                      Interferes with or disrupts the operation of the Platform or the networks connected to the Platform;
                    </li>
                    <li>
                      Impersonates any person or entity or misrepresents your affiliation with any person or entity;
                    </li>
                    <li>
                      Attempts to gain unauthorized access to any portion or feature of the Platform, or any other systems or networks connected to the Platform.
                    </li>
                  </ul>
                </li>
              </ol>
            </li>
            <li>
              <h4>Intellectual Property</h4>
              <ol>
                <li>
                  All content and materials available on the Platform, including but not limited to text, graphics, logos, images, videos, and software, are the property of [Your Company Name] or its licensors and are protected by copyright, trademark, and other intellectual property laws.
                </li>
                <li>
                  You are granted a limited, non-exclusive, non-transferable license to access and use the Platform for your personal and non-commercial use only.
                </li>
                <li>
                  You agree not to reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on the Platform, except as expressly permitted by these Terms.
                </li>
              </ol>
            </li>

            <li>
              <h4>Privacy Policy</h4>
              <ol>
                <li>
                  Your use of the Platform is subject to our Privacy Policy, which is incorporated by reference into these Terms. Please review our Privacy Policy [link to Privacy Policy] for information about how we collect, use, and disclose your personal information.
                </li>
              </ol>
            </li>

            <li>
              <h4>Termination</h4>
              <ol>
                <li>
                  We reserve the right to suspend or terminate your access to the Platform at any time and for any reason without prior notice.
                </li>
                <li>
                  Upon termination, all licenses and rights granted to you under these Terms will immediately cease, and you must cease all use of the Platform.
                </li>
              </ol>
            </li>

            <li>
              <h4>Disclaimer of Warranties</h4>
              <p>
                The platform is provided on an "As Is" and "As Available" basis, without any warranties of any kind, either express or implied. To the fullest extent permitted by law, we disclaim all warranties, express or implied, including but not limited to implied warranties of merchantability, fitness for a particular purpose, and non-infringement.
              </p>
            </li>

            <li>
            <h4>Limitation of Liability</h4>
              <p>
                In no event will [your company name] or its affiliates, licensors, service providers, employees, agents, officers, or directors be liable for any indirect, incidental, special, consequential, or punitive damages, arising out of or in connection with your use of or inability to use the platform.
              </p>
            </li>
            <li>
            <h4>Governing Law</h4>
              <p>
                These Terms and any dispute arising out of or in connection with these Terms or your use of the Platform shall be governed by and construed in accordance with the laws of India, without regard to its conflict of law provisions.
              </p>
            </li>
            <li>
            <h4>Changes to Terms</h4>
              <p>
                We reserve the right to update or modify these Terms at any time without prior notice. By continuing to access or use the Platform after we have posted any such changes, you agree to be bound by the revised Terms.
              </p>
            </li>
            <li>
            <h4>Contact Us</h4>
            </li>
          </ol>
          If you have any questions about these Terms, please contact us at INFO@LUSHAITECH.COM.
        </div>
      </Container>
    </div>
  )
}