import { useRef } from "react"
import Container from "../../Atoms/layouts/container/Container"
import style from "./Footer.module.scss"
import { socialProfiles } from "../../../static_data/page_data";
import { NavLink } from "react-router-dom";

export default function Footer() {

  const { current: year } = useRef(new Date().getFullYear());

  return (
    <footer className={style.Footer}>
      <Container className={`${style.container} py-30 d-flex justify-content-space-between align-items-center flex-wrap-wrap h-100`}
      >
        <ul className={`${style.Links} text-align-mobile-center`}>
          <li>
            <NavLink to="/contact" className="d-block static-link-1">
              Contact Us
            </NavLink>
          </li>
          <li>
            <NavLink to="/terms" className="d-block static-link-1">Terms & Conditions</NavLink>
          </li>
          <li>
            <a href="/docs/Privacy policy for Diaspora.pdf" target="_blank" className="d-block static-link-1">Policy</a>
          </li>
        </ul>
        <div className="d-flex align-items-center mt-mobile-20">
          <p>&copy;{year}, All rights reserved</p>
          <ul className="d-flex mr-10 color-111 ml-20 m-0 p-0">
            {socialProfiles.map(profile =>
              <li key={profile.id} className="d-block">
                <a href={profile.link} className="p-5 static-link-1" target="_blank">
                  <ion-icon name={profile.icon}></ion-icon>
                </a>
              </li>
            )}
          </ul>

        </div>
      </Container>
    </footer>
  )
}