import style from "./ServicesCard.module.scss"

export default function ServicesMiniCard({ data }) {
  return (
    <div className={style.ServicesMiniCard}>

      <div className="IconContainer" style={{ backgroundColor: data.color }}>
        <ion-icon name={data.icon}></ion-icon>
      </div>

      <div className="CardBody">
        <h4>{data.title}</h4>
        <p>{data.short_description} </p>
      </div>
    </div>
  )
}