let idCouter = 1;

const company_leaders = {
  list: [
    {
      id: idCouter++,
      subTitle: "Founder, CEO",
      img_url: `${process.env.PUBLIC_URL}/images/lawmnakima.jpg`,
      title: "Lawmna Kima",
      description: "Lawmna brings more than 18 years of experience in Business Intelligence, Data Engineering, Cloud Modernization & AI Services. He has implemented Cloud modenization for clients like Apple, MGM, Goldman Sachs, Warner Bros etc."
    },
    {
      id: idCouter++,
      subTitle: "Founder, CFO",
      img_url: `${process.env.PUBLIC_URL}/images/remruatkima.jpg`,
      title: "Lalremruat Kima",
      description: "Mr. Lalremruatkima brings forth over 14 years of extensive work experience across diverse sectors including IT, Development, and Market Research. With a proven track record in project implementation, monitoring, and evaluation, as well as adept team and stakeholder management skills, he has significantly contributed to various government project implementation and operation. His expertise spans strategic planning, resource allocation, and fostering collaborative relationships, ensuring successful project outcomes aligned with organizational objectives."
    },
    {
      id: idCouter++,
      subTitle: "Co Founder, Marketing Lead",
      img_url: `${process.env.PUBLIC_URL}/images/remruata.jpg`,
      title: "C Lalremruata",
      description: "A marketing professional with over 6 years of experience in project handling and management across various sectors for both national and international companies. His expertise lies in crafting innovative marketing strategies that boost brand awareness and customer engagement."
    },
    {
      id: idCouter++,
      subTitle: "Co Founder, Engineering Lead",
      img_url: `${process.env.PUBLIC_URL}/images/lalnuntluanga.jpg`,
      title: "Lalnuntluanga",
      description: "A Senior Software Engineer with more than 10 years of experience in Web Application development. He is an expert in building an intuitive and performant web app, leading to user satisfaction and client success. His crafts have been proudly demoed to clients on multiple occasions by his past employers."
    },
  ]
}

const what_we_do = {
  list: [
    {
      id: idCouter++,
      title: "Empowering the Local Community",
      icon_name: "footsteps-outline",
      subTitle: "Nurturing young talents",
      description: "By focusing on the empowerment of Mizo students and professionals, LushAI-Tech focuses on nurturing local talent in the fields of data engineering, cloud modernization, and AI. This emphasis on local talent development helps in bridging the skill gap and fostering a technology-savvy workforce within the region"
    },
    {
      id: idCouter++,
      title: "Innovation and Technology Advancement",
      icon_name: "cloud-outline",
      subTitle: "AI & Cloud Modernization",
      description: "LushAI-Tech's commitment to providing advanced solutions in AI, data engineering, and cloud modernization contributes to the technological advancement of the industries it serves. By integrating cutting-edge technologies and practices, LushAI-Tech helps organizations stay competitive in a rapidly evolving digital landscape."
    },
    {
      id: idCouter++,
      title: "Education and Training",
      icon_name: "school-outline",
      subTitle: "Building a skilled workforce",
      description: "LushAI-Tech's focus on education and training is crucial in building a knowledgeable and skilled workforce. By offering specialized training and educational resources, LushAI-Tech not only enhances individual career prospects but also contributes to the overall growth and competitiveness of the industry."
    },
    {
      id: idCouter++,
      title: "Industry Collaboration",
      icon_name: "business-outline",
      subTitle: "Partnership & Shared Knowledge",
      description: "LushAI-Tech's role in the industry is also marked by its collaborations with other businesses and educational institutions. These partnerships can lead to innovative solutions, shared knowledge, and a stronger ecosystem that benefits all stakeholders involved."
    },
    {
      id: idCouter++,
      title: "Driving Digital Transformation",
      icon_name: "bicycle-outline",
      subTitle: "Enhance business efficiency and innovation",
      description: "By assisting organizations in adopting modern data practices and cloud technologies, LushAI-Tech is at the forefront of driving digital transformation. This transformation is essential for businesses to improve their efficiency, agility, and innovation capabilities."
    },
    {
      id: idCouter++,
      title: "Promoting Research and Development",
      icon_name: "bulb-outline",
      subTitle: "Innovate & thrive",
      description: "If LushAI-Tech is involved in research and development, it would contribute to the industry by introducing new technologies, methodologies, and best practices. This would not only enhance their service offerings but also contribute to the broader field of technology."
    },
    {
      id: idCouter++,
      title: "Global Reach with Local Focus",
      icon_name: "earth-outline",
      subTitle: "community-focused tech development.",
      description: "While LushAI-Tech may have a global perspective, its focus on empowering the local Mizo community ensures that the benefits of global technological advancements are localized, creating a unique model of community-focused tech development."
    },
  ]
}

const ourServices = [
  {
    color: "#45b26b",
    icon : "business-outline",
    title: "Public Sector",
    short_description: "Partner with public sector departments in taking projects related to digital transformation and platform creation"
  },
  {
    color: "#ef466f",
    icon : "school-outline",
    title: "Online Training Course",
    short_description: "Offer online training courses and partnering with public sector departments to undertake projects in digital transformation and platform development."
  },
  {
    color: "#3772ff",
    icon : "cellular-outline",
    title: "Partner with Enterprise/Institute ",
    short_description: "Provide services to private enterprise or institutes in any projects related to digital transformation, consulting services, platform creation or training courses."
  },
  {
    color: "#9757d7",
    icon : "book-outline",
    title: "IT Consulting Service ",
    short_description: "LushAI-Tech provides IT consulting  services to address your organization’s unique challenges and needs."
  },
]

const socialProfiles = [
  {
    id: idCouter++,
    site: "Instagram",
    icon: "logo-instagram",
    link: "https://www.instagram.com/lushaitech/"
  },
  {
    id: idCouter++,
    site: "Facebook",
    icon: "logo-facebook",
    link: "https://www.facebook.com/profile.php?id=61558397932138"
  },
]

export { company_leaders, what_we_do, ourServices, socialProfiles };
