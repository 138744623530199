import { BrowserRouter } from 'react-router-dom';
import "react-toastify/dist/ReactToastify.css";
import './App.scss';
import "./assets/fonts/font_face.css";
import './assets/scss_index.scss';
import Footer from "./Components/Organisms/footer/Footer";
import TopNavBar from './Components/Organisms/top_nav_bar/TopNavBar';
import AppRouter from './config/app_router/AppRouter';
import { ToastContainer } from 'react-toastify';
import { useEffect } from 'react';
import { PublicStore } from './store/PublicStore';

function App() {

  const { fetchData } = PublicStore();

  useEffect(() => {
    fetchData();
  }, [])


  return (
    <div className="App">
      <BrowserRouter>
        <TopNavBar />
        <div className='Main'>
          <AppRouter />
        </div>
        <Footer />
        <ToastContainer
          position="top-right"
        />
      </BrowserRouter>
    </div>
  );
}

export default App;
