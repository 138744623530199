import React, { useState, useEffect } from "react";
import Container from "../../Components/Atoms/layouts/container/Container";
import DisplayGrid from "../../Components/Atoms/layouts/grid/DisplayGrid";
import SectionHeader from "../../Components/Atoms/text_labels/SectionHeader";
import { Bar } from "react-chartjs-2";
import data from "../../assets/hosted_json/mz-yt-data.json"; // Adjust the path accordingly
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css'; // Import Leaflet CSS
import L from 'leaflet';
import './MizoYoutube.css'; 

// API link https://lushaitech-data-science.s3.eu-north-1.amazonaws.com/mz-yt-data.json

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export default function MizoYoutube() {
  

  const [markers, setMarkers] = useState([]);

  useEffect(() => {
    // Sample coordinates for demonstration; replace these with actual coordinates
    const countryCoordinates = {
      DK: [56.2639, 9.5018],
      ES: [40.4637, -3.7492],
      GB: [55.3781, -3.4360],
      IN: [20.5937, 78.9629],
      MY: [4.2105, 101.9758],
      NO: [60.4720, 8.4689],
      US: [37.0902, -95.7129],
    };

    const markers = data.country_data.map(country => ({
      country: country.country,
      count: country.channel_count,
      position: countryCoordinates[country.country],
    }));

    setMarkers(markers);
  }, []);


  const [isChannelListVisible, setIsChannelListVisible] = useState(false);

  const toggleChannelList = () => {
      setIsChannelListVisible(prevState => !prevState);
  };


    // Data for the bar chart
    const chartData = {
        labels: data.chart_data.years,
        datasets: [
            {
                label: 'Number of Channels',
                data: data.chart_data.counts,
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
        ],
    };

    // Options for the bar chart
    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'top',
            },
            title: {
                display: true,
                text: 'Number of Channels Over the Years',
            },
        },
    };


    return (
        <div className="other-page-container color-brand-color1 bg-color-brand-color4">
            <Container className="py-30 ContentMin">
                <SectionHeader className="mb-10">
                    Mizo YouTube Data Analytics
                </SectionHeader>

                 
                <div className="toggle-section">
            {/* Toggle Button for Channel List */}
            <button 
                onClick={toggleChannelList} 
                className="toggle-button"
            >
                {isChannelListVisible ? "Hide Channel List" : "Show Channel List"}
            </button>
            
            {isChannelListVisible && (
                <div className="channel-list-container">
                    {data.channel_list.length > 0 ? (
                        <ul className="channel-list">
                            {data.channel_list.map((channel, index) => (
                                <li key={index}>
                                    {channel}
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No channels available.</p>
                    )}
                </div>
            )}
        </div>
               <p className="mt-20">Disclaimer: Channel lak kim loh tam tak ala awm a, rawn dah belh zel a ni ang.</p>



                {/* Display some Summary Statistics in a Grid */}
                <h3 className="section-title mt-40">Summary</h3>
                <div className="summary-grid mt-20">
                    <div className="summary-item">
                        <strong>Total Channels</strong>
                        <p>{data.total_channels}</p>
                    </div>
                    <div className="summary-item">
                        <strong>Total Subscribers</strong>
                        <p>{data.total_subscribers}</p>
                    </div>
                    <div className="summary-item">
                        <strong>Total Videos</strong>
                        <p>{data.total_videos}</p>
                    </div>
                    <div className="summary-item">
                        <strong>Total Views</strong>
                        <p>{data.total_views}</p>
                    </div>
                </div>

                {/* Bar Chart */}
                <div className="mt-100">
                    <h3 className="section-title">Channel Growth Over the Years</h3>
                    <Bar data={chartData} options={chartOptions} />

                </div>
                {/* Top 10 Channels by Subscribers and Views Tables */}
        <div className="table-container mt-100">
          {/* Top 10 Channels by Subscribers Table */}
          <div className="table-wrapper">
            <h3 className="section-title">Top 10 Channels by Subscribers</h3>
            <table className="top-10-table mt-20">
              <thead>
                <tr>
                  <th>Rank</th>
                  <th>Channel Title</th>
                  <th>Subscribers</th>
                </tr>
              </thead>
              <tbody>
                {data.top_10_subscribers.map((channel, index) => (
                  <tr key={index}>
                    <td>{channel.Rank}</td>
                    <td>{channel.channel_title}</td>
                    <td>{channel.subscriber_count.toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Top 10 Channels by Views Table */}
          <div className="table-wrapper">
            <h3 className="section-title">Top 10 Channels by Views</h3>
            <table className="top-10-table mt-20">
              <thead>
                <tr>
                  <th>Rank</th>
                  <th>Channel Title</th>
                  <th>Views</th>
                </tr>
              </thead>
              <tbody>
                {data.top_10_views.map((channel, index) => (
                  <tr key={index}>
                    <td>{channel.Rank}</td>
                    <td>{channel.channel_title}</td>
                    <td>{channel.view_count.toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <h3 className="section-title mt-100">Mizo YouTuber around the Globe</h3>
      <MapContainer center={[20, 0]} zoom={2} style={{ height: '500px', width: '100%' }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {markers.map((marker, idx) => (
          <Marker
            key={idx}
            position={marker.position}
            icon={L.divIcon({
              className: 'custom-icon',
              html: `<div>${marker.count}</div>`,
            })}
          >
            <Popup>
              {marker.country}: {marker.count} Channels
            </Popup>
          </Marker>
        ))}
      </MapContainer>

        <p className="coe mt-50">Powered by COE AI/Data Science</p>
            </Container>

            {/* <ReleaseAnimation/> */}

        </div>
    )
}
