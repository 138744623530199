import { NavLink } from "react-router-dom";
import Container from "../../Components/Atoms/layouts/container/Container";
import SectionHeader from "../../Components/Atoms/text_labels/SectionHeader";

export default function AdminIndex() {
  return (
    <div className="other-page-container bg-color-brand-color2 color-111">
      <Container className="py-80">
        <SectionHeader>Administrator</SectionHeader>

        <ul className="mt-60">
          <li>
            <NavLink className="p-10 d-block">
              Manage website content
            </NavLink>
          </li>
          <li>
            <NavLink className="p-10 d-block" to="/admin/manage-career-openings">
              Manage Career openings
            </NavLink>
          </li>
        </ul>
      </Container>
    </div>
  )
}