import Container from "../../Components/Atoms/layouts/container/Container";
import DisplayGrid from "../../Components/Atoms/layouts/grid/DisplayGrid";
import SectionHeader from "../../Components/Atoms/text_labels/SectionHeader";
import WhatWeDoCard from "../../Components/Organisms/Cards/what_we_do_card/WhatWeDoCart";
import { PublicStore } from "../../store/PublicStore";

export default function AiHubPage() {


  const { globalData } = PublicStore();


  return (
    <div className="other-page-container color-brand-color1 bg-color-brand-color4">
      <Container className="py-100 ContentMin">
        <SectionHeader>
          AI Hub
        </SectionHeader>

        <DisplayGrid className="mt-80" col={3} colTablet={2} colMobile={1} gap={20}>
          {globalData?.ai_hub_app_list?.map(eachApp =>
            <a href={eachApp.url} key={eachApp.id} className={`static-link-1 ${eachApp.disabled === true ? 'disabled-card-link' : ''}`}>
              <WhatWeDoCard data={eachApp} />
            </a>
          )}
        </DisplayGrid>
      </Container>


      {/* <ReleaseAnimation/> */}

    </div>
  )
}