import style from "./ChatTriggerBtn.module.scss";

export default function GsmChatInline({className="", chatLinkUrl="", phone=""}) {

  
  return (
    <a href={chatLinkUrl || `tel:${phone}`} className={`d-flex align-items-center ${className} ${style.ChatContainer} ${style.GsmChat}`} target="_blank">
      <ion-icon style={{ fontSize: "30px" }}
        name="call-outline"></ion-icon>
      <span className="ml-10">
        Phone Call
      </span>
    </a>
  )
}