import { Fragment, useEffect, useState } from "react";

export default function SelectGeneric({ className = "", defaultValue = "", options=[], name = "", required = false, placeholder = "select", onchange }) {


  const [selectValstate, setSelectState] = useState("");

  useEffect(() => {
    if (defaultValue !== undefined) {
      setSelectState(defaultValue)
    }
  }, [defaultValue])

  function onSelectChange(event) {
    onchange(event)
    setSelectState(event.target.value)
  }

  return (
    <select
      name={name}
      required={required}
      className={`${className} w-100 h-px-40 form-control-medium form-input-theme`}
      value={selectValstate}
      onChange={onSelectChange}
    >
      <option value="">{placeholder}</option>
      {options.map((dataStr, i) =>
        <Fragment key={i}>
          <option value={dataStr}>{dataStr}</option>
        </Fragment>
      )}
    </select>
  )
}