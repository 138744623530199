import Modal from "../../../Components/Organisms/modal/Modal";
import style from "../admin.module.scss";

export default function NewOpeningModal({ isOpen = false, onClose }) {
  return (
    <Modal isOpen={isOpen} onModalClosed={onClose} className={style.OpeningModal}>
      <form className={style.OpeningForm}>
        <div className="form-group">
          <label>Job Title</label>
          <input
            placeholder="Job Title"
          />
        </div>
      </form>
    </Modal>
  )
}