import { useRef, useState } from "react";
import Button from "../../Components/Atoms/form_elements/Button";
import Container from "../../Components/Atoms/layouts/container/Container";
import SectionHeader from "../../Components/Atoms/text_labels/SectionHeader";
import SelectGeneric from "../../Components/Organisms/forms/select/SelectGeneric";
import InlineLoader from './../../Components/Atoms/loader/InlineLoader';
import Api from "../../services/api/Api";
import { public_api } from "../../services/api/ApiList";
import { toast } from "react-toastify";
import { PublicStore } from "../../store/PublicStore";

export default function Internship() {

  const { globalData } = PublicStore();
  const { current: formPayload } = useRef({})
  const { current: resumePayload } = useRef({})
  const [loading, setLoading] = useState(false);


  function submitForm(event) {
    event.preventDefault();
    console.log(formPayload);

    const finalPayload = new FormData();
    finalPayload.append("details", JSON.stringify(formPayload));
    finalPayload.append("resume_file", resumePayload.file);
    console.log(finalPayload);

    setLoading(true);
    Api.post(public_api.submit_internships, finalPayload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then(({ data }) => {
      console.log(data);
      if(data.success_bool === true) {
        toast.success(data.message);
      }
    }).catch(err => {
      console.log(err)
      toast.error(err.message)
    }).finally(() => {
      setLoading(false);
    })
  }

  function setFormFilePayload(event) {
    const file = event?.target?.files?.[0];
    console.log(file.type)
    if (file && file.type.match(/application.*/)) {
      resumePayload.file = file;
    }
  }

  function setFormPayload(key, value) {
    formPayload[key] = value;
  }


  return (
    <div className="other-page-container bg-color-brand-color4 color-111">
      <Container className="py-80">
        <SectionHeader>Apply for internship</SectionHeader>
        <p className="mt-10">We'll evaluate your submission and get back to you.</p>

        <form className="simple-form mt-30" onSubmit={submitForm}>
          <div className="form-group">
            <label className="d-block">Name</label>
            <input type="text" name="Name" placeholder="First Name Last Name" required onChange={e => { setFormPayload("name", e.target.value) }} />
          </div>
          <div className="form-group">
            <label className="d-block">Email</label>
            <input type="email" name="Email" placeholder="Email id" required onChange={e => { setFormPayload("email", e.target.value) }} />
          </div>
          <div className="form-group">
            <label className="d-block">Phone</label>
            <input type="tel" name="Phone" placeholder="Phone / Mobile no" required onChange={e => { setFormPayload("phone", e.target.value) }} />
          </div>
          <div className="form-group">
            <label className="d-block">College</label>
            <input type="text" name="College" placeholder="College" required onChange={e => { setFormPayload("college", e.target.value) }} />
          </div>
          <div className="form-group">
            <label className="d-block">Internship duration</label>
            <SelectGeneric
              placeholder="Select"
              required={true}
              options={
                globalData?.internship_application_form?.duration || []
              }
              onchange={e => {
                setFormPayload("duration", e.target.value)
              }}
            />
          </div>
          <div className="form-group">
            <label className="d-block">Fields of Exposure expected</label>
            <SelectGeneric
              placeholder="Select"
              required={true}
              options={
                globalData?.internship_application_form?.field_of_exposure || []
              }
              onchange={e => {
                setFormPayload("field_of_exposure", e.target.value)
              }}
            />
          </div>
          <div className="form-group">
            <label className="d-block">Expected Start Date/Month</label>
            <input type="date" placeholder="Expected Start Date/Month" required onChange={e => { setFormPayload("start_date", e.target.value) }} />
          </div>
          <div className="form-group">
            <label className="d-block">Upload Resume <small>(Accepted docx/pdf)</small></label>
            <input type="file" placeholder="Resume" accept="application/msword, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document" required onChange={setFormFilePayload} />
          </div>
          <div className="form-group">
            <Button themeType="theme2" className="w-100 mt-30 d-flex align-items-center justify-content-center" type="submit">
              SUBMIT
              {loading ?
                <InlineLoader className="ml-10" themeType="light" />
                : ''}
            </Button>
          </div>

        </form>
      </Container>
    </div>
  )
}