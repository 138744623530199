import { useEffect, useState } from "react";
import Button from "../../../Components/Atoms/form_elements/Button";
import Container from "../../../Components/Atoms/layouts/container/Container";
import DisplayGrid from "../../../Components/Atoms/layouts/grid/DisplayGrid";
import InlineLoader from "../../../Components/Atoms/loader/InlineLoader";
import SectionHeader from "../../../Components/Atoms/text_labels/SectionHeader";
import DocViewer from "../../../Components/Organisms/doc_viewer/DocViewer";
import Modal from '../../../Components/Organisms/modal/Modal';
import Api from "../../../services/api/Api";
import { admin_api } from "../../../services/api/ApiList";


export default function InternApplications() {

  const [interns, setInterns] = useState([]);
  const [resumeUrl, setResumeUrl] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    Api.get(admin_api.get_interns).then(({ data }) => {
      setInterns(data.result_data);
    }).finally(() => {
      setLoading(false);
    })
  }, [])


  return (
    <div className="other-page-container bg-color-brand-color4 color-111">
      <Container className="py-80">
        <SectionHeader>Intership Application</SectionHeader>
        <p className="mt-10">All internship applications displays here</p>

        {loading ?
          <InlineLoader text="Getting applicants" />
          : ''}

        <table className="table mt-50 d-mobile-none">
          <thead>
            <tr>
              <th>Date of <br />Submission</th>
              <th>Name</th>
              <th>Email</th>
              <th>Mobile</th>
              <th>College</th>
              <th>Duration</th>
              <th>Field</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              interns?.map(intern =>

                <tr key={intern.id}>
                  <td>{new Date(intern.create_time).toLocaleDateString("en-In")}</td>
                  <td>{intern.name}</td>
                  <td>{intern.email}</td>
                  <td>{intern.phone}</td>
                  <td>{intern.college}</td>
                  <td>{intern.duration}</td>
                  <td>{intern.field_of_exposure}</td>
                  <td>
                    <button onClick={() => {
                      setResumeUrl(intern.resume_url)
                    }}>View Resume</button>
                  </td>
                </tr>

              )
            }
          </tbody>
        </table>

        <div className="mt-50 d-tablet-none d-desktop-none d-mobile-block">
          <DisplayGrid gap={15}>
            {interns?.map((intern) =>
              <div className="p-10 bg-color-brand-color1 border-radius-px-5 color-fff" key={intern.id}>
                <div className="d-flex flex-wrap-wrap">
                  <span className="d-block p-5">Name: <b>{intern.name}</b></span>
                  <span className="d-block p-5">Email: <b>{intern.email}</b></span>
                  <span className="d-block p-5">Phone: <b>{intern.phone}</b></span>
                  <span className="d-block p-5">College: <b>{intern.college}</b></span>
                  <span className="d-block p-5">Duration: <b>{intern.duration}</b></span>
                  <span className="d-block p-5">Exposure: <b>{intern.field_of_exposure}</b></span>
                </div>
                <div className="mt-10 text-align-right">
                  <a href={intern.resume_url} target="_blank" rel="noreferrer">
                    <Button className="no-wrap">View Resume</Button>
                  </a>
                </div>
              </div>
            )}
          </DisplayGrid>
        </div>
      </Container>


      <Modal isOpen={!!resumeUrl} onModalClosed={() => {
        setResumeUrl("")
      }}>
        <DocViewer docUrl={resumeUrl} />
      </Modal>

    </div>
  )
}