import style from "./WhatWeDoCart.module.scss"

export default function WhatWeDoCard({ data }) {
  return (
    <div className={`${style.WhatWeDoCard} d-flex justify-content-between`}>
      <div className={style.IconContainer}>
        <ion-icon class="font-size-80" name={data.icon_name}></ion-icon>
      </div>
      <div className="pl-20">
        <h3>{data.title}</h3>
        <p><b>{data.subTitle}</b></p>
        <p>{data.description} </p>
      </div>
    </div>
  )
}
