import { useState } from "react";
import { PublicStore } from "../../../store/PublicStore";
import style from "./OurApps.module.scss";

export default function OurApps() {

  const [openState, setOpenState] = useState(false);
  const { globalData } = PublicStore();


  return (
    <div className="position-relative">
      <div className="d-flex align-items-center color-fff">
        <button className="bg-color-transparent border-width-0 color-brand-color1 font-size-25 px-10" onClick={e => { setOpenState(true) }}>
          <ion-icon name="apps-outline" class="d-block"></ion-icon>
        </button>
      </div>


      <div className={`${style.AppListContainer} ${openState ? style.Active : ''}`}>
        {globalData?.nav_menu?.map(app => (
          <a key={app.id} href={app.url} target="_blank" rel="noreferrer" className="d-block" onClick={e => { setOpenState(false) }}>
            <ion-icon name={app.icon_name}></ion-icon>
            <h4>{app.short_name}</h4>
          </a>
        ))}
      </div>

      <div onClick={e => { setOpenState(false) }} className={`${style.BackDrop} ${openState ? style.Active : ''}`}></div>

    </div>
  )
}