import axios from 'axios';

console.log("process.env.REACT_APP_API_BASE_URL");
console.log(process.env.REACT_APP_API_BASE_URL);

const Api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 10000,
});

Api.interceptors.response.use(
  response => response,
  error => {
    console.log(error)

    // if (error.code === "ERR_NETWORK") {
    //   toastError("There is a problem with network");
    //   return;
    // }

    // if (error.response.status === 401) {
    //   if (expiryToastDebounce) {
    //     clearTimeout(expiryToastDebounce);
    //   }
    //   expiryToastDebounce = setTimeout(() => {
    //     toastError("Your session expired. Please relogin");
    //     sessionStorage.clear();
    //     localStorage.clear();
    //     reset();
    //     resetAccount();
    //     navigate("/login");
    //   }, 10)
    //   return;
    // }


    if (error?.response?.data?.message) {
      error.message = error.response.data.message;
    }


    return Promise.reject(error);

  });

export default Api;