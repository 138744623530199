import { useEffect, useState } from "react"
import style from "./DocViewer.module.scss"

export default function DocViewer({ docUrl }) {

  const [ext, setExt] = useState("");

  useEffect(() => {
    if (docUrl) {

      const url = new URL(docUrl)
      const ext = url.pathname.split(".").pop();
      setExt(ext);

    }


  }, [docUrl])

  return (
    <div className={`bg-color-fff ${style.DocViewer}`}>
      {ext === "pdf" ?
        <object data={docUrl} type="application/pdf" className={style.PdfViewer}>
          alt : <a href="data/test.pdf">test.pdf</a>
        </object>
        : ''}

      {
        ["docx", "doc", "pptx", "ppt"].indexOf(ext) !== -1 ?
          <iframe title="office viewer" src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(docUrl)}`}  className={style.PdfViewer}/>
          : ''
      }
    </div>
  )
}