import { NavLink } from "react-router-dom"
import Button from "../../Atoms/form_elements/Button"
import Container from "../../Atoms/layouts/container/Container"
import style from "./HomeBanner.module.scss"
import DisplayGrid from "../../Atoms/layouts/grid/DisplayGrid"

export default function HomeBanner() {
  return (

    <div className={`${style.HomeBanner} p-20`} style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/banner1.jpg)` }}>
      <Container className={`d-flex align-items-center justify-content-space-between flex-wrap-wrap ${style.innerContainer}`}>
        <div>
          <h1>Empowering Minds, <br />Transforming Futures </h1>
          <h4 className="color-brand-color1 mt-10">with Cutting-Edge Technology</h4>

          <div className="d-flex flex-wrap-wrap mt-20">
            <NavLink className="d-block w-mobile-100" to="/careers">
              <Button themeType="theme2">Join Our Team</Button>
            </NavLink>
            <NavLink to="/contact" className="d-block w-mobile-100 ml-10 ml-mobile-0 mt-mobile-10">
              <Button themeType="theme3">Let's Talk</Button>
            </NavLink>
          </div>
        </div>
        <div className="d-tablet-none d-mobile-none">
          {/* <img className={style.RightImg} src={`${process.env.PUBLIC_URL}/images/graph.png`}/> */}
          {/* <SvgSprite name="graph" size="400" className={style.GraphGraphic}/> */}
        </div>
      </Container>
    </div>
  )
}