import Container from "../../Components/Atoms/layouts/container/Container";
import DisplayGrid from "../../Components/Atoms/layouts/grid/DisplayGrid";
import SectionHeader from "../../Components/Atoms/text_labels/SectionHeader";
import ServicesMiniCard from "../../Components/Organisms/Cards/services_card/ServicesMiniCard";
import { ourServices } from "../../static_data/page_data";



export default function Services() {
  return (
    <div className="bg-color-brand-color4 color-111 other-page-container">
      <Container className="py-120 ">
        <SectionHeader> Our Services</SectionHeader>

        <DisplayGrid className="mt-50" col={2} colTablet={2} colMobile={1} gap={15}>
          {ourServices.map((service, index) =>
            <ServicesMiniCard key={service.id} data={service} />
          )}
        </DisplayGrid>
      </Container>
    </div>
  )
}