import { what_we_do } from "../../../../static_data/page_data";
import Container from "../../../Atoms/layouts/container/Container";
import DisplayGrid from "../../../Atoms/layouts/grid/DisplayGrid";
import SectionHeader from "../../../Atoms/text_labels/SectionHeader";
import WhatWeDoCard from "../../Cards/what_we_do_card/WhatWeDoCart";


export default function WhatWeDo() {
  return (
    <div className={`py-80 color-111 bg-color-brand-color4`}>
      <Container>

        <SectionHeader className="w-50 w-mobile-100 flex-order-mobile-0 mb-50 text-align-mobile-center mb-mobile-70">What We Do!!!</SectionHeader>

        <DisplayGrid col={2} colMobile={1} gap={50}>
          {
            what_we_do.list.map((data) =>
              <div key={data.id}>
                <WhatWeDoCard data={data} />
              </div>
            )
          }
        </DisplayGrid>

        {/* <div className="d-flex justify-content-space-between flex-wrap-wrap">
          <div className="w-50 w-mobile-100 flex-order-mobile-1">
            
          </div>
          <SectionHeader className="w-50 w-mobile-100 flex-order-mobile-0 mb-50 text-align-center">What We Do</SectionHeader>
        </div> */}
      </Container>
    </div>
  )
}