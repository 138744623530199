import { NavLink } from "react-router-dom";
import Button from "../../Components/Atoms/form_elements/Button";
import Container from "../../Components/Atoms/layouts/container/Container";
import SectionHeader from "../../Components/Atoms/text_labels/SectionHeader";
import { PublicStore } from "../../store/PublicStore";
import { useEffect, useState } from "react";
import Api from "../../services/api/Api";
import { public_api } from "../../services/api/ApiList";
import HTMLReactParser from "html-react-parser/lib/index";
import JobApplicationFormModal from "./JobApplicationFormModal";
import InlineLoader from "../../Components/Atoms/loader/InlineLoader";

export default function CareersIndex() {

  const { globalData } = PublicStore();
  const [jobOpenings, setJobOpenings] = useState([]);
  const [jobApplicationSelected, setActiveJobApplication] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    Api.get(public_api.get_job_openings).then(({ data }) => {
      if (data.success_bool) {
        setJobOpenings(data.result_data);
        // setJobOpenings([...data.result_data, ...data.result_data]);
        // setJobOpenings([]);
      }
    }).finally(() => {
      setLoading(false)
    })
  }, []);

  return (
    <div className="bg-color-brand-color4 full-page-background other-page-container">
      <Container className="py-80">


        <SectionHeader>Job Openings</SectionHeader>
        {
          loading? <InlineLoader className="mb-40" text="Getting open positions..."/> : ""
        }
        {
          !loading && jobOpenings.length === 0? <p className="mb-40">No job openings at the moment</p> : ""
        }
        {
          jobOpenings?.map(job =>
            <div key={job.id} className="mt-30">
              <h2>{job.job_title}</h2>

              <h4 className="mt-20">Descriptions</h4>
              <div>
                {
                  HTMLReactParser(job.description)
                }
              </div>
              <h4 className="mt-20">Qualifications</h4>
              <div>
                {
                  HTMLReactParser(job.qualifications)
                }
              </div>
              <Button themeType="theme2" className="mt-30" onClick={() => {
                setActiveJobApplication(job);
              }}>
                Apply Now
              </Button>
              <hr className="mb-40 mt-50" />
            </div>

          )
        }

        <JobApplicationFormModal isOpen={!!jobApplicationSelected} jobObj={jobApplicationSelected} onClose={() => {
          setActiveJobApplication(null);
        }} />



        <SectionHeader>
          Our Internship Program
        </SectionHeader>
        <p className="mb-30">We have open an internship program for the year 2024 to 2025 for multiple roles.</p>

        <h4>Fields of Exposure</h4>
        <ul className="mt-5">
          {globalData?.internship_application_form?.field_of_exposure?.map((eachField, index) =>
            <li key={index}>{eachField}</li>
          )}
        </ul>

        <h4>Durations</h4>
        <ul className="mt-5">
          {globalData?.internship_application_form?.duration?.map((eachField, index) =>
            <li key={index}>{eachField}</li>
          )}
        </ul>

        <NavLink className="mt-60 d-block" to="/careers/apply-internship">
          <Button themeType="theme2">
            Apply Now
          </Button>
        </NavLink>


      </Container>
    </div>
  )
}