import { useEffect, useState } from "react";
import style from "./Modal.module.scss";


export default function Modal({ children, isOpen = false, size="xs", onModalClosed }) {

  const [openState, setOpenState] = useState(isOpen);

  useEffect(() => {
    setOpenState(isOpen);
  }, [isOpen])


  function closeModal() {
    setOpenState(false);
    onModalClosed();
  }

  if (!openState) {
    return ""
  }

  return (
    <div className={style.Modal} data-size={size}>

      <div className={style.Content}>
        {children}
      </div>

      <div className={style.BackDrop} onClick={closeModal}></div>
    </div>
  )
}