import { NavLink } from "react-router-dom";
import { ourServices } from "../../../../static_data/page_data";
import Button from "../../../Atoms/form_elements/Button";
import Container from "../../../Atoms/layouts/container/Container";
import DisplayGrid from "../../../Atoms/layouts/grid/DisplayGrid";
import SectionHeader from "../../../Atoms/text_labels/SectionHeader";
import ServicesMiniCard from "../../Cards/services_card/ServicesMiniCard";


export default function OurServices() {
  return (
    <div className="bg-color-brand-color1 color-fff">
      <Container className="py-80">

        <SectionHeader> Our Services</SectionHeader>

        <DisplayGrid className="mt-50" col={4} colTablet={2} colMobile={1} gap={15}>
          {ourServices.map((service, index) =>
            <ServicesMiniCard key={index} data={service} />
          )}
        </DisplayGrid>

        <div className="d-flex justify-content-center mt-30">
          <NavLink to="/services">
            <Button themeType="theme2">View Service Details</Button>
          </NavLink>
        </div>
      </Container>
    </div>
  )
}