import style from "./LeadersCard.module.scss"

export default function LeadersCard ({data}){
  return (
    <div className={`${style.LeadersCard}`}>
      <div className={style.ProfilePictureContainer}>
        <img src={data.img_url}/>
      </div>
      <div className={style.TextSection}>
        <h3>{data.title}</h3>
        <h4>{data.subTitle}</h4>
        {/* <p>{data.description}</p> */}
      </div>
    </div>
  )
}