import { useRef, useState } from "react";
import Modal from "../../Components/Organisms/modal/Modal";
import Button from "../../Components/Atoms/form_elements/Button";
import InlineLoader from "../../Components/Atoms/loader/InlineLoader";
import Api from "../../services/api/Api";
import { public_api } from "../../services/api/ApiList";
import { toast } from "react-toastify";

export default function JobApplicationFormModal({ isOpen, jobObj, onClose }) {

  const { current: formPayload } = useRef({})
  const { current: resumePayload } = useRef({})
  const [loading, setLoading] = useState(false);

  function setFormPayload(key, value) {
    formPayload[key] = value;
  }

  function setFormFilePayload(event) {
    const file = event?.target?.files?.[0];
    console.log(file.type)
    if (file && file.type.match(/application.*/)) {
      resumePayload.file = file;
    }
  }

  function submitForm(event) {
    event.preventDefault();
    setFormPayload("job_id", jobObj.id);
    setFormPayload("job_title", jobObj.job_title);
    const finalPayload = new FormData();
    finalPayload.append("details", JSON.stringify(formPayload));
    finalPayload.append("resume_file", resumePayload.file);
    console.log("finalPayload");
    console.log(finalPayload);

    setLoading(true);
    Api.post(public_api.submit_job_application, finalPayload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then(({ data }) => {
      console.log(data);
      if(data.success_bool === true) {
        toast.success(data.message);
      }
    }).catch(err => {
      console.log(err)
      toast.error(err.message)
    }).finally(() => {
      setLoading(false);
    })

  }

  if (!jobObj) {
    return ""
  }

  return (
    <Modal isOpen={isOpen} onModalClosed={onClose} size="sm">
      <form className="simple-form" onSubmit={submitForm}>
        <h3 className="mb-20 text-align-center">Applying for "{jobObj.job_title}"</h3>
        <div className="form-group">
          <label className="d-block">Name</label>
          <input type="text" name="Name" placeholder="First Name Last Name" required onChange={e => { setFormPayload("name", e.target.value) }} />
        </div>
        <div className="form-group">
          <label className="d-block">Email</label>
          <input type="email" name="Email" placeholder="Email id" required onChange={e => { setFormPayload("email", e.target.value) }} />
        </div>
        <div className="form-group">
          <label className="d-block">Phone</label>
          <input type="tel" name="Phone" placeholder="Phone / Mobile no" required onChange={e => { setFormPayload("phone", e.target.value) }} />
        </div>
        <div className="form-group">
          <label className="d-block">Upload Resume <small>(Accepted docx/pdf)</small></label>
          <input type="file" placeholder="Resume" accept="application/msword, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document" required onChange={setFormFilePayload} />
        </div>
        <div className="form-group">
          <Button themeType="theme2" className="w-100 mt-30 d-flex align-items-center justify-content-center" type="submit">
            SUBMIT
            {loading ?
              <InlineLoader className="ml-10" themeType="light" />
              : ''}
          </Button>
        </div>
      </form>
    </Modal>
  )
}