import Container from "../../Components/Atoms/layouts/container/Container";
import EmailChatInline from "../../Components/Atoms/social_media/ChatTriggerBtn/EmailappChatInline";
import GsmChatInline from "../../Components/Atoms/social_media/ChatTriggerBtn/GsmCallInline";
import WhatsappChatInline from "../../Components/Atoms/social_media/ChatTriggerBtn/WhatsappChatInline";
import SectionHeader from "../../Components/Atoms/text_labels/SectionHeader";

export default function ContactUs() {
  return (
    <div className="bg-color-brand-color1 color-fff full-page-background other-page-container">
      <Container className="py-100 long-para-content">
        <SectionHeader className="section-header-1 dark-brown-font mb-20">
          We are
          <br />
          Happy to help</SectionHeader>


        <p>Feel free to reach out to us using any of these. We strive to respond within one business day
        </p>

        <WhatsappChatInline className="mt-50" chatLinkUrl="https://wa.me/+918259881121"/>
        <EmailChatInline className="mt-10" email="info@lushaitech.com"/>
        <GsmChatInline className="mt-10" phone="+91 82598 81121"/>



      </Container>
    </div>
  )
}