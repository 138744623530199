import HomeBanner from "../../Components/Organisms/home_banner/HomeBanner";
import AboutMiniSection from "../../Components/Organisms/home_sections/about_us/AboutMiniSection";
import OurAppsSection from "../../Components/Organisms/home_sections/our_apps_section/OurAppsSection";
import OurLeaders from "../../Components/Organisms/home_sections/our_leaders/OurLeaders";
import OurServices from "../../Components/Organisms/home_sections/our_services/OurServices";



export default function Home(){
  
  return (
    <div>
      <HomeBanner/>
      <AboutMiniSection/>
      <OurServices/>
      <OurAppsSection/>
      <OurLeaders/>
    </div>
  )
}