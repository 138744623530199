import AboutUs from "../../Components/Organisms/home_sections/about_us/AboutUs";
import WhatWeDo from "../../Components/Organisms/home_sections/what_we_do/WhatWeDo";

export default function AboutUsPage() {
  return (
    <div className="other-page-container bg-color-brand-color1 
 color-fff">

      <AboutUs/>
      <WhatWeDo/>

    </div>
  )
}