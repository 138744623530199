import { useState } from "react";
import NewOpeningModal from "./NewOpeningModal";
import Container from "../../../Components/Atoms/layouts/container/Container";
import SectionHeader from "../../../Components/Atoms/text_labels/SectionHeader";
import Button from "../../../Components/Atoms/form_elements/Button";

export default function CareerOpenings() {

  const [modalToggleNewOpening, openOpeningModal] = useState(false);


  return (
    <div className="grey-bg full-page-background other-page-container">
      <Container className={"py-50"}>
        <div className="d-flex justify-content-space-between align-items-center">
          <SectionHeader>Career Openings</SectionHeader>
          <Button themeType="theme1" onClick={() => {
            openOpeningModal(true);
            console.log("onclick");
          }}>New Opening</Button>
        </div>
      </Container>

      <NewOpeningModal isOpen={modalToggleNewOpening} onClose={()=>{openOpeningModal(false)}}/>


    </div>
  )
}