import { PublicStore } from "../../../../store/PublicStore";
import Container from "../../../Atoms/layouts/container/Container";
import DisplayGrid from "../../../Atoms/layouts/grid/DisplayGrid";
import SectionHeader from "../../../Atoms/text_labels/SectionHeader";
import LeadersCard from "../../Cards/leaders_card/LeadersCard";


export default function OurLeaders() {

  const { globalData } = PublicStore();

  

  return (
    <div className="py-80 bg-color-brand-color1 color-fff">
      <Container>

        <div className="mb-mobile-50 mb-50 text-align-mobile-center">
          <SectionHeader>Our Leaders</SectionHeader>
        </div>
        <DisplayGrid col={5} colTablet={3} colMobile={1} gap={30}>

          {
            globalData?.company_leaders.map(data =>
              <LeadersCard key={data.id} data={data} />
            )
          }
        </DisplayGrid>
      </Container>
    </div>
  )
}