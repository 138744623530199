import { NavLink } from "react-router-dom";
import Button from "../../../Atoms/form_elements/Button";
import Container from "../../../Atoms/layouts/container/Container";
import DisplayGrid from "../../../Atoms/layouts/grid/DisplayGrid";
import GridItem from "../../../Atoms/layouts/grid/GridItem";
import SectionHeader from "../../../Atoms/text_labels/SectionHeader";
import style from "./About.module.scss"

export default function AboutMiniSection() {
  return (
    <Container className="py-80">
      <DisplayGrid col={2} colTablet={1} colMobile={1}>
        <div className={style.AboutImgContainer}>
          <img src={`${process.env.PUBLIC_URL}/images/lushai_logo.svg`}></img>
        </div>
        <GridItem colSpan={1}>
          <SectionHeader className="w-50 w-mobile-100 flex-order-mobile-0 mb-30 mb-mobile-50 text-align-mobile-center">About Our <br />Company</SectionHeader>
          <p className="mt-20 text-align-mobile-center">
            Lushai Technologies & Consulting specializes in Data Engineering, Cloud Modernization, and Artificial Intelligence. LuhsAI-Tech is founded by experienced Mizo IT professionals with the aim of providing cutting-edge solutions and training in these domains. The company is dedicated to advancing the knowledge and skills of Mizo students and professionals, equipping them with the latest technological innovations and industry practices.

            <br />
            <br />
            At LushAI-Tech, the focus is not just on delivering services but also on educational empowerment, ensuring that the local community can benefit from the global advancements in technology. Through their offerings, individuals and organizations can learn about and implement advanced AI applications, data engineering techniques, and cloud modernization strategies, all tailored to meet contemporary industry standards and challenges.

            <br />
            <br />

            For individuals interested in these fields, whether for educational or professional purposes, LushAI-Tech serves as a resourceful and impactful partner, aligning with the broader goal of technological empowerment and skill development within the Mizo community and beyond.
          </p>
          <div className="text-align-center mt-30">
            <NavLink to="/about-us">
              <Button themeType="theme2" className="mt-20">Read More</Button>
            </NavLink>
          </div>
        </GridItem>
      </DisplayGrid>
    </Container>
  )
}